import { Box } from '@mui/material';
import type { BoxProps } from '@mui/system';
import type { Node } from '@tiptap/pm/model';
import { TextSelection } from '@tiptap/pm/state';
import { type Editor, EditorContent } from '@tiptap/react';
import {
  type DragEvent,
  type MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { editorStyles } from '../utils/editor-input-styles';
import type { TableMenuPosition } from './table-menu';
import { TableMenu } from './table-menu';

interface TemplateEditorProps {
  editor: Editor | null;
  sx?: BoxProps['sx'];
}

export function TextEditor({ editor, sx = editorStyles }: TemplateEditorProps) {
  const [tableMenu, setTableMenu] = useState<TableMenuPosition>(null);

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const label = event.dataTransfer.getData('application/x-custom-node');
    const value = event.dataTransfer.getData('application/x-custom-node-value');

    const coords = { left: event.clientX, top: event.clientY };
    const position = editor?.view.posAtCoords(coords);
    if (label && position && editor) {
      event.stopPropagation(); // Prevent the default text insertion
      editor?.commands.addVariable({ label, value, at: position.pos });
    }
  };

  const currentTableLink = editor?.getAttributes('table');

  const handleTableMenuOptions: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!editor) return;

    const coords = { left: event.clientX, top: event.clientY };
    const pos = editor.view.posAtCoords(coords);
    if (!pos) return;

    const resolvedPos = editor.view.state.doc.resolve(pos.pos);
    const cellNode = resolvedPos.nodeAfter || resolvedPos.node(-1);

    if (
      (event.type === 'contextmenu' &&
        cellNode &&
        cellNode.type.name === 'tableCell') ||
      cellNode.type.name === 'tableHeader'
    ) {
      event.preventDefault();
    }

    if (
      cellNode &&
      (cellNode.type.name === 'tableCell' ||
        cellNode.type.name === 'tableHeader')
    ) {
      const transaction = editor.view.state.tr.setSelection(
        TextSelection.near(resolvedPos),
      );
      editor.view.dispatch(transaction);

      setTableMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        pos: resolvedPos.pos,
      });
    }
  };

  useEffect(() => {
    if (!editor) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (currentTableLink && event.key === 'ArrowDown') {
        const { doc } = editor.state;
        let positionAfterTable: number | null = null;
        // Traverse the document nodes
        doc.descendants((node: Node, pos: number) => {
          if (node.type.name === 'table') {
            // Position after the table is the position at the start of the node plus its size
            positionAfterTable = pos + node.nodeSize;
            return false; // Stop the traversal once the table is found
          }
          return true;
        });
        if (positionAfterTable) {
          editor.chain().focus().insertContentAt(positionAfterTable, ' ').run();
        }
      }
    };
    // Add the event listener to the editor's editable DOM element
    const editorEl = editor.view.dom;
    editorEl.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on unmount
    return () => {
      editorEl.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor, currentTableLink]);

  return (
    <>
      <Box
        sx={sx}
        onDrop={handleDrop}
        onContextMenu={handleTableMenuOptions}
        component={EditorContent}
        editor={editor}
      />
      <TableMenu
        editor={editor}
        tableMenu={tableMenu}
        setTableMenu={setTableMenu}
      />
    </>
  );
}
