import { uploadTemporaryFile } from '@tyro/api';
import { type TFunction, useTranslation } from '@tyro/i18n';
import { nanoid } from 'nanoid';
import { extractDocumentCSS } from '../utils/extract-document-css';
import { getReactAsHtml } from '../utils/get-react-as-html';
import { type ReturnOfUseToast, useToast } from './use-toast';

export async function exportReactToPdf(
  componentGetter: () => React.ReactElement,
  { toast, closeAllToasts }: ReturnOfUseToast,
  t: TFunction<'common'[], undefined, 'common'[]>,
  additionalCss = '',
) {
  toast(t('common:exporting'), {
    variant: 'info',
  });

  try {
    const component = componentGetter();
    const tableHtml = getReactAsHtml(component);

    const extractedCSS = extractDocumentCSS();

    const fullHTML = `
        <!DOCTYPE html>
        <html>
        <head>
          <title>${document.title}</title>
          <style>
            ${extractedCSS}
            ${additionalCss}
          </style>
        </head>
        <body>
          ${tableHtml}
        </body>
        <script>
          window.print();
        </script>
        </html>
      `;

    const file = new File([fullHTML], `${nanoid(8)}.html`, {
      type: 'text/html',
    });

    const fileResponse = await uploadTemporaryFile(file);

    closeAllToasts();
    if (fileResponse?.fileUrl) {
      window.open(fileResponse?.fileUrl, '_blank', 'noreferrer');
    } else {
      toast(t('common:exportFailed'), {
        variant: 'error',
      });
    }
  } catch (e) {
    console.error(e);
    closeAllToasts();
    toast(t('common:exportFailed'), {
      variant: 'error',
    });
  }
}

export function useExportReactToPdf() {
  const toast = useToast();
  const { t } = useTranslation(['common']);

  return {
    exportReactToPdf: (
      componentGetter: () => React.ReactElement,
      additionalCss?: string,
    ) => exportReactToPdf(componentGetter, toast, t, additionalCss),
  };
}
