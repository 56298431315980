import get from 'lodash/get';
import set from 'lodash/set';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
} from 'react';
import type { Path, PathValue } from 'react-hook-form';
import { useLocalStorage } from 'react-use';
import type { UserPreferences } from '../@types/user-preferences';
import { getUser, useUser } from '../hooks/use-user';

export type PreferencesContextValue<
  T extends Path<UserPreferences>,
  Value extends PathValue<UserPreferences, T> | undefined,
> = (
  preferencePath: T,
  defaultValue?: Value,
) => [
  value: Value | undefined,
  setValue: Dispatch<SetStateAction<Value | undefined>>,
];

const PreferencesContext = createContext<
  | PreferencesContextValue<
      Path<UserPreferences>,
      PathValue<UserPreferences, Path<UserPreferences>>
    >
  | undefined
>(undefined);

type PreferencesProviderProps = {
  children: ReactNode;
};

const getCacheKey = (
  globalUserId: number | undefined,
  activeProfileId: number | undefined,
) => `tyro:prefs-${globalUserId}-${activeProfileId}`;

export function PreferencesProvider({ children }: PreferencesProviderProps) {
  const { activeProfile, user } = useUser();
  const cacheKey = getCacheKey(user?.id, activeProfile?.id);
  const [preferences, setPreferences] = useLocalStorage<object>(cacheKey);

  const value = useCallback<
    PreferencesContextValue<
      Path<UserPreferences>,
      PathValue<UserPreferences, Path<UserPreferences>>
    >
  >(
    (key: string, defaultValue?: unknown) => [
      get(preferences, key) ?? defaultValue,
      (value: unknown) => {
        if (typeof value === 'function') {
          setPreferences(
            set({ ...preferences }, key, value(get(preferences, key))),
          );

          return;
        }
        setPreferences(set({ ...preferences }, key, value));
      },
    ],
    [preferences, setPreferences],
  );

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
}

export function usePreference<T extends Path<UserPreferences>>(
  preferencePath: T,
  defaultValue: PathValue<UserPreferences, T>,
): [
  value: PathValue<UserPreferences, T>,
  setValue: Dispatch<SetStateAction<PathValue<UserPreferences, T>>>,
];

export function usePreference<T extends Path<UserPreferences>>(
  preferencePath: T,
): [
  value: PathValue<UserPreferences, T> | undefined,
  setValue: Dispatch<SetStateAction<PathValue<UserPreferences, T> | undefined>>,
];

export function usePreference<T extends Path<UserPreferences>>(
  preferencePath: T,
  defaultValue?: PathValue<UserPreferences, T>,
) {
  const context = useContext(PreferencesContext) as
    | PreferencesContextValue<T, PathValue<UserPreferences, T>>
    | undefined;
  if (context === undefined) {
    throw new Error('usePreference must be used within a PreferencesContext');
  }
  return context(preferencePath, defaultValue);
}

export async function getPreference<T extends Path<UserPreferences>>(
  preferencePath: T,
  defaultValue: PathValue<UserPreferences, T>,
): Promise<PathValue<UserPreferences, T>>;

export async function getPreference<T extends Path<UserPreferences>>(
  preferencePath: T,
): Promise<PathValue<UserPreferences, T> | undefined>;

export async function getPreference<T extends Path<UserPreferences>>(
  preferencePath: T,
  defaultValue?: PathValue<UserPreferences, T>,
) {
  const { activeProfile, user } = await getUser();
  const cacheKey = getCacheKey(user?.id, activeProfile?.id);
  const cache = localStorage.getItem(cacheKey);

  if (!cache) return defaultValue;

  try {
    const preferences = JSON.parse(cache);
    return get(preferences, preferencePath) ?? defaultValue;
  } catch {
    return defaultValue;
  }
}

export async function setPreference<
  T extends Path<UserPreferences>,
  Value = PathValue<UserPreferences, T> | undefined,
>(preferencePath: T, value: Value): Promise<void> {
  const { activeProfile, user } = await getUser();
  const cacheKey = getCacheKey(user?.id, activeProfile?.id);
  const cache = localStorage.getItem(cacheKey);

  if (!cache) return;

  try {
    const preferences = JSON.parse(cache);
    set(preferences, preferencePath, value);
    localStorage.setItem(cacheKey, JSON.stringify(preferences));
  } catch {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Failed to set preference ${preferencePath} with value ${value}`,
      );
    }
    return;
  }
}
