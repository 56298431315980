import { useQuery } from '@tanstack/react-query';
import type { UseQueryReturnType } from '../@types/use-query-return-type';
import { gqlClient } from '../clients';
import { graphql } from '../gql';
import type { FileTransferFilter } from '../gql/graphql';
import { queryClient } from '../query-client';
import { coreApiKeys } from './keys';

const fileList = graphql(/* GraphQL */ `
  query file_transfer_list($filter: FileTransferFilter) {
    file_transfer_list(filter: $filter) {
      id
      feature
      fileName
      fileUrl
      referenceId
    }
  }
`);

const fileListQuery = (filter: FileTransferFilter) => ({
  queryKey: coreApiKeys.fileTransferList(filter),
  queryFn: async () => gqlClient.request(fileList, { filter }),
});

export function getFileList(filter: FileTransferFilter) {
  return queryClient.fetchQuery(fileListQuery(filter));
}

export function useFileList(filter: FileTransferFilter) {
  return useQuery({
    ...fileListQuery(filter),
    select: ({ file_transfer_list }) => file_transfer_list,
  });
}

export type ReturnTypeFromUseFileList = UseQueryReturnType<
  typeof useFileList
>[number];
