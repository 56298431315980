import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// theme
import { ThemeProvider } from '@tyro/core';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthProvider, DataProvider, PreferencesProvider } from '@tyro/api';
import { AppShell } from '@tyro/app-shell';
import { useCurrentLanguage } from '@tyro/i18n';

export default function App() {
  const { dayjsLocale } = useCurrentLanguage();

  return (
    <DataProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
        <AuthProvider>
          <PreferencesProvider>
            <MotionLazyContainer>
              <ThemeProvider>
                <AppShell />
                <ReactQueryDevtools buttonPosition="bottom-left" />
              </ThemeProvider>
            </MotionLazyContainer>
          </PreferencesProvider>
        </AuthProvider>
      </LocalizationProvider>
    </DataProvider>
  );
}
