import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { Autocomplete, type AutocompleteProps, Box } from '@mui/material';

export interface TableAutocompleteProps<TOption>
  extends CustomCellEditorProps<unknown, TOption | TOption[]> {
  options: TOption[];
  getOptionLabel?: (option: TOption) => string;
  optionIdKey?: TOption extends string | number ? never : keyof TOption;
  AutocompleteProps: Omit<
    AutocompleteProps<TOption, boolean, false, false>,
    'options' | 'renderInput'
  >;
}

function checkTableAutocompleteProps<TOption>(
  props: TableAutocompleteProps<TOption>,
): asserts props is TableAutocompleteProps<TOption> {
  if (process.env.NODE_ENV !== 'production') {
    if (!Array.isArray(props.options)) {
      throw new Error(
        `Please provide an array of options to cellEditorSelector.params.options for the TableAutocomplete component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }

    if (typeof props.getOptionLabel !== 'function') {
      throw new Error(
        `Please provide a getOptionLabel function to cellEditorSelector.params.getOptionLabel for the TableAutocomplete component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }

    if (!props.optionIdKey) {
      throw new Error(
        `Please provide a optionIdKey to cellEditorSelector.params.optionIdKey for the TableAutocomplete component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }
  }
}

export function TableAutocomplete<TOption>(
  props: TableAutocompleteProps<TOption>,
) {
  const {
    value,
    onValueChange,
    stopEditing,
    options = [],
    optionIdKey,
    getOptionLabel,
    AutocompleteProps: autocompleteProps,
  } = props;

  checkTableAutocompleteProps(props);

  return (
    <Autocomplete
      sx={{
        width: '100%',
        height: '100%',
        '& input': {
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          outline: 'none',
          border: 'none',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          color: 'inherit',
          paddingX: 1,
        },
      }}
      renderInput={(params) => (
        <Box sx={{ height: '100%' }} ref={params.InputProps.ref}>
          <input
            // biome-ignore lint/a11y/noAutofocus: Needs to autofocus for keyboard users
            autoFocus
            {...params.inputProps}
          />
        </Box>
      )}
      componentsProps={{
        popper: {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
          sx: {
            minWidth: 280,
          },
        },
      }}
      onChange={(_, value) => {
        onValueChange(value);
        stopEditing();
      }}
      {...autocompleteProps}
      autoHighlight
      value={value}
      options={options}
      openOnFocus
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) =>
        optionIdKey ? option[optionIdKey] === value[optionIdKey] : false
      }
    />
  );
}
