export const editorStyles = {
  fontSize: '13px',
  ':not(:has(.template-wrapper))': {
    minWidth: '794px',
    maxWidth: '794px',
    minHeight: '1123px',
    border: '1px solid #f3f1f1',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: 'background.paper',
    p: '71px',
  },
  '.tiptap': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '&:focus': {
      outline: 'none',
    },
    '& table': {
      borderCollapse: 'collapse',
      margin: 0,
      overflow: 'hidden',
      tableLayout: 'fixed',
      width: '100%',
      '& td, & th': {
        border: '2px solid #ced4da',
        boxSizing: 'border-box',
        minWidth: '1em',
        padding: '3px 5px',
        position: 'relative',
        verticalAlign: 'top',
        '& > *': {
          marginBottom: 0,
        },
      },
      '& th': {
        backgroundColor: '#f1f3f5',
        fontWeight: 'bold',
        textAlign: 'left',
      },
      '& .selectedCell:after': {
        background: 'rgba(200, 200, 255, 0.4)',
        content: '""',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 2,
      },
      '& .column-resize-handle': {
        backgroundColor: '#adf',
        bottom: '-2px',
        position: 'absolute',
        right: '-2px',
        pointerEvents: 'none',
        top: 0,
        width: '4px',
      },
      '& p': {
        margin: 0,
      },
    },
    '& p': {
      margin: 0,
      '&.is-editor-empty:first-of-type::before': {
        color: 'text.disabled',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  },
  '& hr': {
    border: 'none',
    borderTop: '1px solid lightgrey',
    margin: '1rem 0',
  },
  '& .tableWrapper': {
    overflowX: 'auto',
  },
  '& .resize-cursor': {
    cursor: 'ew-resize',
  },
  '.two-column-layout, .three-column-layout, .four-column-layout': {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px dashed',
    borderColor: 'slate.300',

    '& .column': {
      textAlign: 'center',

      '&:first-of-type': {
        textAlign: 'left',
      },
      '&:last-of-type': {
        textAlign: 'right',
      },
    },
  },
  '.page-break': {
    display: 'block',
    borderTop: ' 1px dashed #ccc',
    marginTop: '30px',
    marginBottom: '12px',
  },
  '.template-wrapper:not(:has(.template-wrapper))': {
    pageBreakAfter: 'always',
    width: '794px',
    margin: '0 auto 10px',
    padding: '71px',
    minHeight: '1123px',
    backgroundColor: 'background.paper',
    border: '1px solid #f3f1f1',
    borderRadius: 2,
    boxSizing: 'border-box',
  },
  '.avatar-photo': {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.avatar-photo > img': {
    width: '100%',
    height: '100%',
  },
};
