import {
  Box,
  Button,
  Menu,
  MenuItem,
  type MenuProps,
  type Palette,
  Tooltip,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Editor } from '@tiptap/react';
import { useTranslation } from '@tyro/i18n';
import { HighlightPenIcon } from '@tyro/icons';
import { type MouseEvent, useState } from 'react';

export enum PickerModeOptions {
  COLOUR = 'colour',
  HIGHLIGHT = 'highlight',
}

type PickerMode = PickerModeOptions.COLOUR | PickerModeOptions.HIGHLIGHT;

type ToolbarColorPickerProps = {
  editor: Editor | null;
  pickerMode: PickerMode;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 'fit-content',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: theme.spacing(0),
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
      height: 'auto',
      padding: theme.spacing(0.5),
    },
  },
}));

const colors = (col: Palette) => [
  col.slate[800],
  col.gray[700],
  col.slate[600],
  col.gray[500],
  col.slate[400],
  col.slate[200],
  col.gray[200],
  col.gray.contrastText,
  col.gray[600],
  col.orange[500],
  col.yellow[400],
  col.green[500],
  col.emerald[400],
  col.cyan[400],
  col.blue[600],
  col.indigo[500],
  col.violet[500],
  col.fuchsia[500],
  col.pink[500],
  col.rose[400],
];

export default function ToolbarColorPicker({
  editor,
  pickerMode,
}: ToolbarColorPickerProps) {
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();
  const [colorValue, setColorValue] = useState<string>(
    pickerMode === PickerModeOptions.COLOUR ? 'slate.800' : 'transparent',
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!editor) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={
          pickerMode === PickerModeOptions.COLOUR
            ? t('common:tooltipTitles.textColour')
            : t('common:tooltipTitles.highlightColor')
        }
      >
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            color: 'slate.800',
            fontWeight: 'regular',
            fontSize: '18px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ':hover': {
              backgroundColor: 'rgba(30, 41, 59, 0.08)',
            },
            minWidth: '20px',
            padding: '4px',
            margin: ' 4px',
          }}
        >
          {pickerMode === PickerModeOptions.COLOUR ? (
            'A'
          ) : (
            <HighlightPenIcon sx={{ width: '22px' }} />
          )}
          <Box
            key={colorValue}
            sx={{
              width: '20px',
              height: '5px',
              backgroundColor: colorValue,
              marginTop:
                pickerMode === PickerModeOptions.HIGHLIGHT ? '7px' : undefined,
            }}
          />
        </Button>
      </Tooltip>

      <Box>
        <StyledMenu
          id="demo-customized-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {colors(palette).map((color) => (
            <MenuItem key={color} style={{ gridRowEnd: 'span 1' }}>
              <Box
                onClick={() => {
                  if (pickerMode === PickerModeOptions.COLOUR) {
                    editor.chain().focus().setColor(color).run();
                  } else {
                    editor.chain().focus().setHighlight({ color }).run();
                  }
                  setColorValue(color);
                  handleClose();
                }}
                sx={{
                  backgroundColor: color,
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  border:
                    color === palette.gray.contrastText
                      ? palette.slate[100]
                      : 'none',
                }}
              />
            </MenuItem>
          ))}
        </StyledMenu>
      </Box>
    </>
  );
}
