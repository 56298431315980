interface EdgeScrollListenerOptions {
  ref: HTMLElement | null;
  scrollThreshold?: number;
  topScrollThreshold?: number;
  rightScrollThreshold?: number;
  bottomScrollThreshold?: number;
  leftScrollThreshold?: number;
  maxScrollSpeed?: number;
}

let scrollInterval: NodeJS.Timeout | null = null;

function startScrolling(
  container: HTMLElement,
  scrollX: number,
  scrollY: number,
) {
  if (scrollInterval) clearInterval(scrollInterval);
  scrollInterval = setInterval(() => {
    container.scrollBy(scrollX, scrollY);
  }, 20);
}

function stopScrolling() {
  if (scrollInterval) clearInterval(scrollInterval);
}

function calculateScrollSpeed(
  distance: number,
  {
    scrollThreshold,
    maxScrollSpeed,
  }: { scrollThreshold: number; maxScrollSpeed: number },
) {
  return Math.min(
    maxScrollSpeed,
    (distance / scrollThreshold) * maxScrollSpeed,
  );
}

export function edgeScrollListener(
  event: React.MouseEvent,
  options: EdgeScrollListenerOptions,
) {
  const {
    ref: container,
    scrollThreshold = 60,
    topScrollThreshold,
    rightScrollThreshold,
    bottomScrollThreshold,
    leftScrollThreshold,
    maxScrollSpeed = 20,
  } = options ?? {};

  if (!container) return;

  const rect = container.getBoundingClientRect();
  const mouseX = event.clientX - rect.left;
  const mouseY = event.clientY - rect.top;

  let scrollX = 0;
  let scrollY = 0;

  const innerLeftScrollThreshold = leftScrollThreshold ?? scrollThreshold;
  const innerRightScrollThreshold = rightScrollThreshold ?? scrollThreshold;

  if (mouseX < innerLeftScrollThreshold) {
    scrollX = -calculateScrollSpeed(innerLeftScrollThreshold - mouseX, {
      scrollThreshold: innerLeftScrollThreshold,
      maxScrollSpeed,
    });
  } else if (mouseX > rect.width - innerRightScrollThreshold) {
    scrollX = calculateScrollSpeed(
      mouseX - (rect.width - innerRightScrollThreshold),
      {
        scrollThreshold: innerRightScrollThreshold,
        maxScrollSpeed,
      },
    );
  }

  const innerTopScrollThreshold = topScrollThreshold ?? scrollThreshold;
  const innerBottomScrollThreshold = bottomScrollThreshold ?? scrollThreshold;

  if (mouseY < innerTopScrollThreshold) {
    scrollY = -calculateScrollSpeed(innerTopScrollThreshold - mouseY, {
      scrollThreshold: innerTopScrollThreshold,
      maxScrollSpeed,
    });
  } else if (mouseY > rect.height - innerBottomScrollThreshold) {
    scrollY = calculateScrollSpeed(
      mouseY - (rect.height - innerBottomScrollThreshold),
      {
        scrollThreshold: innerBottomScrollThreshold,
        maxScrollSpeed,
      },
    );
  }

  if (scrollX !== 0 || scrollY !== 0) {
    startScrolling(container, scrollX, scrollY);
  } else {
    stopScrolling();
  }
}
