import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  Stack,
  Typography,
} from '@mui/material';

export interface MiniPieStatProps {
  value: number;
  label: string;
  description: (values: { value: number; total: number }) => string;
  total: number;
  color: Exclude<CircularProgressProps['color'], undefined | 'inherit'>;
}

export function MiniPieStat({
  value,
  label,
  description,
  total,
  color,
}: MiniPieStatProps) {
  let cappedPercent = 0;
  if (total !== 0) {
    const percent = (value / total) * 100;
    cappedPercent =
      percent > 99 ? Number(percent.toFixed(1)) : Math.round(percent) || 0;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          color={color}
          sx={(theme) => ({
            '&': {
              position: 'absolute',
              color: theme.palette[color].lighter,
            },
          })}
          value={100}
          size={60}
        />
        <CircularProgress
          variant="determinate"
          color={color}
          value={cappedPercent}
          size={60}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${cappedPercent}%`}</Typography>
        </Box>
      </Box>
      <Stack>
        <Typography variant="subtitle2">{label}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ lineHeight: 24 / 14 }}
        >
          {description({ value, total })}
        </Typography>
      </Stack>
    </Stack>
  );
}
