import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './calendar-schedule-search.svg';

export const CalendarScheduleSearchIcon = forwardRef<
  SVGSVGElement,
  SvgIconProps
>((props, ref) => (
  <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
));

if (process.env.NODE_ENV !== 'production') {
  CalendarScheduleSearchIcon.displayName = 'CalendarScheduleSearchIcon';
}
