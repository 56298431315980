import type { TFunction } from '@tyro/i18n';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const getHumanizedTime = (minutes: number, t: TFunction<'common'>) => {
  const timeDuration = dayjs.duration(minutes, 'minutes');

  if (timeDuration.asYears() >= 1) {
    return t('timePostfix.year', { year: Math.floor(timeDuration.asYears()) });
  }

  if (timeDuration.asMonths() >= 1) {
    return t('timePostfix.month', {
      month: Math.floor(timeDuration.asMonths()),
    });
  }

  if (timeDuration.asWeeks() >= 1) {
    return t('timePostfix.week', { week: Math.floor(timeDuration.asWeeks()) });
  }

  if (timeDuration.asDays() >= 1) {
    return t('timePostfix.day', { day: Math.floor(timeDuration.asDays()) });
  }

  if (timeDuration.asHours() >= 1) {
    return t('timePostfix.hour', { hour: Math.floor(timeDuration.asHours()) });
  }

  return t('timePostfix.minute', { minute: minutes });
};

export const getHoursAndMinutes = (minutes: number, t: TFunction<'common'>) => {
  if (minutes < 60 && minutes >= 0) {
    return t('timePostfix.minute', { minute: minutes });
  }

  const isNegative = minutes < 0;
  const hours = Math.floor(Math.abs(minutes / 60));
  const remainingMinutes = minutes % 60;
  return `${isNegative ? '-' : ''}${t('timePostfix.hour', {
    hour: Math.abs(hours),
  })} ${t('timePostfix.minute', {
    minute: Math.abs(remainingMinutes),
  })}`;
};
