export const CustomStyles = {
  '.two-column-layout, .three-column-layout, .four-column-layout': {
    display: 'flex',
    justifyContent: 'space-between',

    '& .column': {
      textAlign: 'center',

      '&:first-of-type': {
        justifyContent: 'flex-start',
      },
      '&:last-of-type': {
        justifyContent: 'flex-end',
      },
      '& p': {
        margin: 0,
      },
    },
  },
  '.two-column-layout .column:first-child, .three-column-layout .column:first-child, .four-column-layout .column:first-child':
    {
      /* Styles for the first column */
      textAlign: 'left',
    },
  '.page-break': {
    display: 'block',
    borderTop: ' 1px dashed #ccc',
    marginTop: '30px',
    marginBottom: '12px',
  },
  table: {
    overflow: 'auto',
    border: '1px solid',
    borderColor: 'slate.200',
    borderCollapse: 'collapse',
    margin: 0,
    tableLayout: 'fixed',
    width: '100%',
    height: 'auto',

    '& th': {
      fontWeight: 600,
      textAlign: 'left',
      backgroundColor: 'slate.100',
    },
    '& td': {
      fontWeight: 400,
    },

    '& th, & td': {
      boxSizing: 'border-box',
      minWidth: '1em',
      padding: '3px 5px',
      position: 'relative',
      verticalAlign: 'top',
      border: '1px solid',
      borderColor: 'slate.200',
    },

    '& td>*, & th>*': {
      margin: 0,
    },
  },
} as const;
