import { AuthProvider, DataProvider, PreferencesProvider } from '@tyro/api';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';
import { ThemeProvider } from '../theme';

export const getReactAsHtml = (component: React.ReactElement) => {
  const div = document.createElement('div');
  const root = createRoot(div);

  flushSync(() => {
    root.render(
      <DataProvider>
        <AuthProvider>
          <PreferencesProvider>
            <MemoryRouter>
              <ThemeProvider>{component}</ThemeProvider>
            </MemoryRouter>
          </PreferencesProvider>
        </AuthProvider>
      </DataProvider>,
    );
  });
  return div.innerHTML;
};
