import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useTranslation } from '@tyro/i18n';
import { ChevronDownIcon } from '@tyro/icons';
import { useEffect, useState } from 'react';

interface ParagraphFormatterProps {
  editor: Editor | null;
}

enum ParagraphStyle {
  NORMAL = 'Normal',
}

type ParagraphOption = ParagraphStyle.NORMAL | 1 | 2 | 3 | 4 | 5 | 6;

const paragraphOptions: ParagraphOption[] = [
  ParagraphStyle.NORMAL,
  1,
  2,
  3,
  4,
  5,
  6,
];

export default function ParagraphFormatter({
  editor,
}: ParagraphFormatterProps) {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [headingPlaceHolder, setHeadingPlaceholder] = useState<ParagraphOption>(
    ParagraphStyle.NORMAL,
  );
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if ('key' in event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: ParagraphOption) => {
    setAnchorEl(null);
    if (editor) {
      if (option === ParagraphStyle.NORMAL) {
        editor.chain().setParagraph().run();
      } else {
        editor.chain().toggleHeading({ level: option }).run();
      }
    }
    setHeadingPlaceholder(option);
  };

  const updateActiveHeading = () => {
    const activeHeading = paragraphOptions.find((level) =>
      editor?.isActive('heading', {
        level: level === ParagraphStyle.NORMAL ? 0 : level,
      }),
    );
    setHeadingPlaceholder(activeHeading || ParagraphStyle.NORMAL);
  };

  useEffect(() => {
    if (!editor) return;
    updateActiveHeading();
    editor.on('transaction', updateActiveHeading);
    return () => {
      editor.off('transaction', updateActiveHeading);
    };
  }, [editor]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
      }}
    >
      <Tooltip title={t('common:styles')} placement="bottom">
        <Box
          role="button"
          tabIndex={0}
          aria-haspopup="true"
          id="dropdown-button"
          aria-controls="dropdown-menu"
          onClick={handleMenuOpen}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            cursor: 'pointer',
          }}
        >
          <Typography fontSize="14px">
            {headingPlaceHolder === ParagraphStyle.NORMAL
              ? ParagraphStyle.NORMAL
              : t('common:heading', {
                  option: headingPlaceHolder,
                })}
          </Typography>
          <ChevronDownIcon />
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        {paragraphOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
            {option === ParagraphStyle.NORMAL
              ? option
              : t('common:heading', { option })}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
