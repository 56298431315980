import { alpha, useTheme } from '@mui/material/styles';
import {
  Colour,
  Reporting_ChartType,
  type Reporting_QueryResult,
} from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import merge from 'lodash/merge';
import { useMemo } from 'react';

import type { ChartOptions, ChartProps } from './types';

export function mapChartQueryForChart(
  chartQuery: Partial<Reporting_QueryResult['charts'][number]> | undefined,
  additionalOptions?: ChartOptions,
): ChartProps {
  switch (chartQuery?.type) {
    case Reporting_ChartType.Donut: {
      const labels: string[] = [];
      const colors: string[] = [];
      const series = (chartQuery.series ?? []).map((item) => {
        labels.push(item.name);
        if (item.colour) colors.push(item.colour);
        return item.series?.[0] ?? 0;
      });

      return {
        type: 'donut',
        series: series,
        options: merge(
          {
            labels,
            colors,
            stroke: {
              width: 1.5,
            },
            legend: {
              horizontalAlign: 'center',
            },
          },
          additionalOptions,
        ),
      };
    }
    case Reporting_ChartType.Bar: {
      const colors: string[] = [];
      const series = (chartQuery.series ?? []).map((item) => {
        if (item.colour) colors.push(item.colour);
        return {
          name: item.name,
          data: item.series,
        };
      });

      return {
        type: 'bar',
        series,
        options: merge(
          {
            colors,
            xaxis: {
              categories: chartQuery?.categories ?? [],
            },
          },
          additionalOptions,
        ),
      };
    }
    default:
      return {
        type: chartQuery?.type ?? 'line',
        series: [],
        options: {},
      };
  }
}

export function useChart(options?: ChartOptions) {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return useMemo<ChartOptions>(() => {
    const LABEL_TOTAL = {
      show: true,
      label: t('common:total'),
      color: theme.palette.text.secondary,
      fontSize: theme.typography.subtitle2.fontSize as string,
      fontWeight: theme.typography.subtitle2.fontWeight,
    };

    const LABEL_VALUE = {
      offsetY: 8,
      color: theme.palette.text.primary,
      fontSize: theme.typography.h4.fontSize as string,
      fontWeight: theme.typography.h4.fontWeight,
    };

    const RESPONSIVE = [
      {
        breakpoint: theme.breakpoints.values.sm, // sm ~ 600
        options: {
          plotOptions: {
            bar: {
              borderRadius: 3,
              columnWidth: '80%',
            },
          },
        },
      },
      {
        breakpoint: theme.breakpoints.values.md, // md ~ 900
        options: {
          plotOptions: {
            bar: {
              columnWidth: '60%',
            },
          },
        },
      },
      ...(options?.responsive ?? []),
    ];

    const colorValues = [...Object.values(Colour), 'indigo'];
    const colors =
      options && Array.isArray(options?.colors) && options.colors.length > 0
        ? options.colors.map((color) => {
            if (colorValues.includes(color)) {
              return theme.palette[color as Colour].main;
            }

            return color;
          })
        : undefined;

    return {
      ...options,

      /** **************************************
       * Chart
       *************************************** */
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
        fontFamily: theme.typography.fontFamily,
        foreColor: theme.palette.text.disabled,
        ...options?.chart,
        animations: {
          enabled: true,
          speed: 360,
          animateGradually: { enabled: true, delay: 120 },
          dynamicAnimation: { enabled: true, speed: 360 },
          ...options?.chart?.animations,
        },
      },

      /** **************************************
       * Colors
       *************************************** */
      colors: colors ?? [
        theme.palette.primary.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.error.main,
        theme.palette.success.main,
        theme.palette.warning.dark,
        theme.palette.success.darker,
        theme.palette.info.dark,
        theme.palette.info.darker,
      ],

      /** **************************************
       * States
       *************************************** */
      states: {
        ...options?.states,
        hover: {
          ...options?.states?.hover,
          filter: {
            type: 'darken',
            value: 0.88,
            ...options?.states?.hover?.filter,
          },
        },
        active: {
          ...options?.states?.active,
          filter: {
            type: 'darken',
            value: 0.88,
            ...options?.states?.active?.filter,
          },
        },
      },

      /** **************************************
       * Fill
       *************************************** */
      fill: {
        opacity: 1,
        ...options?.fill,
        gradient: {
          type: 'vertical',
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
          ...options?.fill?.gradient,
        },
      },

      /** **************************************
       * Data labels
       *************************************** */
      dataLabels: {
        enabled: false,
        ...options?.dataLabels,
      },

      /** **************************************
       * Stroke
       *************************************** */
      stroke: {
        width: 0,
        curve: 'smooth',
        lineCap: 'round',
        ...options?.stroke,
      },

      /** **************************************
       * Grid
       *************************************** */
      grid: {
        strokeDashArray: 3,
        borderColor: theme.palette.divider,
        ...options?.grid,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          ...options?.grid?.padding,
        },
        xaxis: {
          lines: {
            show: false,
          },
          ...options?.grid?.xaxis,
        },
      },

      /** **************************************
       * Axis
       *************************************** */
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        ...options?.xaxis,
      },
      yaxis: {
        tickAmount: 5,
        ...options?.yaxis,
      },

      /** **************************************
       * Markers
       *************************************** */
      markers: {
        size: 0,
        strokeColors: theme.palette.background.paper,
        ...options?.markers,
      },

      /** **************************************
       * Tooltip
       *************************************** */
      tooltip: {
        theme: 'false',
        fillSeriesColor: false,
        x: {
          show: true,
        },
        ...options?.tooltip,
      },

      /** **************************************
       * Legend
       *************************************** */
      legend: {
        show: true,
        position: 'top',
        fontWeight: 500,
        fontSize: '13px',
        horizontalAlign: 'right',
        markers: { radius: 12 },
        labels: {
          colors: theme.palette.text.primary,
        },
        ...options?.legend,
        itemMargin: {
          horizontal: 8,
          vertical: 8,
          ...options?.legend?.itemMargin,
        },
      },

      /** **************************************
       * plotOptions
       *************************************** */
      plotOptions: {
        ...options?.plotOptions,
        // plotOptions: Bar
        bar: {
          borderRadius: 4,
          columnWidth: '48%',
          borderRadiusApplication: 'end',
          ...options?.plotOptions?.bar,
        },

        // plotOptions: Pie + Donut
        pie: {
          ...options?.plotOptions?.pie,
          donut: {
            ...options?.plotOptions?.pie?.donut,
            labels: {
              show: true,
              ...options?.plotOptions?.pie?.donut?.labels,
              value: {
                ...LABEL_VALUE,
                ...options?.plotOptions?.pie?.donut?.labels?.value,
              },
              total: {
                ...LABEL_TOTAL,
                ...options?.plotOptions?.pie?.donut?.labels?.total,
              },
            },
          },
        },

        // plotOptions: Radialbar
        radialBar: {
          ...options?.plotOptions?.radialBar,
          hollow: {
            margin: -8,
            size: '100%',
            ...options?.plotOptions?.radialBar?.hollow,
          },
          track: {
            margin: -8,
            strokeWidth: '50%',
            background: alpha(theme.palette.gray[500], 0.16),
            ...options?.plotOptions?.radialBar?.track,
          },
          dataLabels: {
            ...options?.plotOptions?.radialBar?.dataLabels,
            value: {
              ...LABEL_VALUE,
              ...options?.plotOptions?.radialBar?.dataLabels?.value,
            },
            total: {
              ...LABEL_TOTAL,
              ...options?.plotOptions?.radialBar?.dataLabels?.total,
            },
          },
        },

        // plotOptions: Radar
        radar: {
          ...options?.plotOptions?.radar,
          polygons: {
            fill: {
              colors: ['transparent'],
            },
            strokeColors: theme.palette.divider,
            connectorColors: theme.palette.divider,
            ...options?.plotOptions?.radar?.polygons,
          },
        },

        // plotOptions: polarArea
        polarArea: {
          rings: {
            strokeColor: theme.palette.divider,
          },
          spokes: {
            connectorColors: theme.palette.divider,
          },
          ...options?.plotOptions?.polarArea,
        },

        // plotOptions: heatmap
        heatmap: {
          distributed: true,
          ...options?.plotOptions?.heatmap,
        },
      },

      /** **************************************
       * Responsive
       *************************************** */
      responsive: RESPONSIVE.reduce((acc: typeof RESPONSIVE, cur) => {
        if (!acc.some((item) => item.breakpoint === cur.breakpoint)) {
          acc.push(cur);
        }
        return acc;
      }, []),
    };
  }, [options]);
}
