import { Node } from '@tiptap/core';
import { type NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import type { StyledVariableChipProps } from './styled-variable-chip';
import { VariableChipComponent } from './styled-variable-chip';

export type VariableChipOptions = {
  label: string;
  value: string;
  at?: number | null | undefined;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variableChip: {
      addVariable: (options: VariableChipOptions) => ReturnType;
      deleteVariableChip: (options: { at: number }) => ReturnType;
    };
  }
}

export const VariableChipNode = Node.create({
  name: 'variableChip',
  group: 'inline',
  inline: true,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      label: {
        default: 'Variable Label',
      },
      value: {
        default: 'Default data value',
      },
    };
  },

  addCommands() {
    return {
      addVariable:
        (options) =>
        ({ tr, dispatch }) => {
          const { label, value, at } = options;

          const position = typeof at === 'number' ? at : tr.selection.head;
          const node = this.type.create({ label, value });

          const transaction = tr.insert(position, node);
          transaction.insert(
            position + node.nodeSize,
            this.editor.schema.text(' '),
          );
          if (dispatch) {
            dispatch(transaction);
          }
          return true;
        },
      deleteVariableChip:
        ({ at }) =>
        ({ tr, dispatch }) => {
          const nodeSize = tr.doc.nodeAt(at)?.nodeSize;
          if (dispatch && nodeSize) {
            dispatch(tr.delete(at, at + nodeSize));
          }
          return true;
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-custom-node]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const label = node.attrs.label as string;
    const labelValue = node.attrs.value as string;

    const updatedLabelDataValue = `\${(v.${labelValue})!}`;

    return [
      'span',
      {
        ...HTMLAttributes,
        'data-custom-node': updatedLabelDataValue,
        'data-label': updatedLabelDataValue,
        class: 'custom-variable-chip',
        label,
      },
      updatedLabelDataValue,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props: NodeViewProps) => {
      const customVariableChipProps: StyledVariableChipProps = {
        ...props,
        node: {
          ...props.node,
          attrs: {
            label: props.node.attrs.label as string,
            value: props.node.attrs.value as string,
          },
        },
        deleteNode: (pos: number) => {
          props.editor.commands.deleteVariableChip({ at: pos });
        },
        getPos: props.getPos,
      };
      return <VariableChipComponent {...customVariableChipProps} />;
    });
  },
});
