import { useMemo } from 'react';
import { matchPath, useLocation, useMatches } from 'react-router-dom';

export function useRouteMatch() {
  const location = useLocation();
  const matches = useMatches();

  return useMemo(() => {
    const matchedRoute = matches.find((match) =>
      matchPath(location.pathname, match.pathname),
    );

    if (!matchedRoute) {
      return null;
    }

    let path = location.pathname;

    for (const [key, value] of Object.entries(matchedRoute.params)) {
      if (value) {
        path = path.replace(value, `:${key}`);
      }
    }

    return path;
  }, [location, matches]);
}
