import type { Theme } from '@mui/material/styles';

export default function TimePicker(theme: Theme) {
  return {
    MuiTimePicker: {
      defaultProps: {
        desktopModeMediaQuery: '@media (min-width:480px)',
      },
    },
  };
}
