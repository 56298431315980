import { TextField, type TextFieldProps, useTheme } from '@mui/material';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';

export type RHFTextFieldProps<TField extends FieldValues> = {
  label?: string;
  variant?:
    | TextFieldProps['variant']
    | 'white-filled'
    | 'white-filled-outlined';
  textFieldProps?: TextFieldProps;
  controlProps: UseControllerProps<TField>;
};

export const RHFTextField = <TField extends FieldValues>({
  label,
  variant,
  textFieldProps,
  controlProps,
}: RHFTextFieldProps<TField>) => {
  const {
    field: { ref, value, onChange, ...restField },
    fieldState: { error },
  } = useController(controlProps);

  const theme = useTheme();
  const { spacing, palette } = theme;

  const isWhiteFilledVariant =
    variant === 'white-filled' || variant === 'white-filled-outlined';

  return (
    <TextField
      {...textFieldProps}
      {...restField}
      onChange={(e) => {
        onChange(e);
        textFieldProps?.onChange?.(e);
      }}
      variant={isWhiteFilledVariant ? 'filled' : variant}
      sx={{
        ...(typeof textFieldProps?.sx === 'function'
          ? textFieldProps.sx(theme)
          : textFieldProps?.sx),
        ...(isWhiteFilledVariant && {
          '& .MuiInputBase-root, & .MuiInputBase-root:focus, & .MuiInputBase-root.Mui-focused, & .MuiInputBase-root:hover, & .MuiInputBase-root, & .MuiInputBase-input:focus, & .MuiInputBase-input.Mui-focused, & .MuiInputBase-input:hover':
            {
              backgroundColor: palette.background.default,
              borderRadius: spacing(1),
            },
          ...(variant === 'white-filled-outlined' && {
            '& .MuiInputBase-root': {
              border: '1px solid',
              borderColor: 'divider',
            },
          }),
        }),
      }}
      value={value ?? ''}
      label={label}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
    />
  );
};
