import type { Person } from '@tyro/api';
import { useMemo } from 'react';

export type DisplayNamePersonProps =
  | Pick<Person, 'title' | 'firstName' | 'lastName' | 'type'>
  | undefined
  | null;

export enum PreferredNameFormat {
  FirstnameSurname = 'FIRST_NAME_SURNAME',
  SurnameFirstname = 'SURNAME_FIRST_NAME',
}

export const displayName = (
  person: DisplayNamePersonProps,
  options?: {
    format: PreferredNameFormat;
  },
): string => {
  if (!person) {
    return '';
  }

  switch (options?.format) {
    case PreferredNameFormat.FirstnameSurname:
      return [person.firstName, person.lastName].filter(Boolean).join(' ');
    default:
      return [person.lastName, person.firstName].filter(Boolean).join(', ');
  }
};

export const displayNames = (
  persons: DisplayNamePersonProps[] | undefined | null,
  options?: {
    separator?: string;
    format?: PreferredNameFormat;
    emptyValue?: string;
  },
): string => {
  const { separator = ', ', format, emptyValue = '' } = options || {};
  if (!persons) {
    return emptyValue;
  }
  return (
    persons
      .map((person) => displayName(person, format ? { format } : undefined))
      .filter(Boolean)
      .join(separator) || emptyValue
  );
};

export function sortByDisplayName(
  studentA: DisplayNamePersonProps,
  studentB: DisplayNamePersonProps,
) {
  const nameA = displayName(studentA);
  const nameB = displayName(studentB);

  return nameA.localeCompare(nameB);
}

export function searchDisplayName<T extends DisplayNamePersonProps>(
  options: T[],
  toSearch: string,
) {
  if (!toSearch) return options;

  const splitInputValue = toSearch.toLowerCase().split(' ');

  return options.filter((option) => {
    const studentName = displayName(option).toLowerCase();
    return splitInputValue.every((string) => studentName.includes(string));
  });
}

export function preferredNameLayoutUtils() {
  return {
    displayName,
    displayNames,
    sortByDisplayName,
    searchDisplayName,
  };
}

export function usePreferredNameLayout() {
  return useMemo(
    () => ({
      displayName,
      displayNames,
      sortByDisplayName,
      searchDisplayName,
    }),
    [],
  );
}

export type ReturnTypeDisplayName = ReturnType<
  typeof usePreferredNameLayout
>['displayName'];

export type ReturnTypeDisplayNames = ReturnType<
  typeof usePreferredNameLayout
>['displayNames'];
