import {
  FormControlLabel,
  type FormControlLabelProps,
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import {
  type FieldValues,
  type Path,
  type PathValue,
  type UseControllerProps,
  useController,
} from 'react-hook-form';

type RHFCheckboxProps<TField extends FieldValues> = {
  label?: FormControlLabelProps['label'];
  checkboxProps?: MuiCheckboxProps;
  controlLabelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
  controlProps: UseControllerProps<TField>;
};

export const RHFCheckbox = <TField extends FieldValues>({
  label,
  controlLabelProps,
  checkboxProps,
  controlProps,
}: RHFCheckboxProps<TField>) => {
  const { field } = useController({
    ...controlProps,
    defaultValue: !!controlProps.defaultValue as PathValue<
      TField,
      Path<TField>
    >,
  });

  return (
    <FormControlLabel
      {...controlLabelProps}
      label={label}
      control={
        <MuiCheckbox
          {...checkboxProps}
          {...field}
          checked={!!field.value}
          onChange={(...args) => {
            field.onChange(...args);
            checkboxProps?.onChange?.(...args);
          }}
        />
      }
    />
  );
};

type CheckboxProps = {
  label?: FormControlLabelProps['label'];
  checkboxProps?: MuiCheckboxProps;
  controlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
};

export function Checkbox({
  label,
  controlLabelProps,
  checkboxProps,
}: CheckboxProps) {
  return (
    <FormControlLabel
      {...controlLabelProps}
      label={label}
      control={<MuiCheckbox {...checkboxProps} />}
    />
  );
}
