import type {
  BackendErrorResponse,
  ParsedErrorDetail,
} from '../@types/backend-error-response';

export function resolveBackendError(error: unknown, fallback: string) {
  let errorMessage = fallback;

  if (typeof error === 'object' && error !== null) {
    const backendError = error as BackendErrorResponse;
    try {
      const parsedError = JSON.parse(
        backendError.response.error,
      ) as ParsedErrorDetail;
      errorMessage = parsedError.detail || errorMessage;
    } catch (parseError) {
      console.error('Error parsing the error message:', parseError);
    }
  }

  return errorMessage;
}
