import { getFileList } from '../api';
import { uploadFile } from '../api/upload-file';
import { FileTransferFeature } from '../gql/graphql';

export async function uploadTemporaryFile(file: File) {
  const fileResponse = await uploadFile({
    documentType: FileTransferFeature.Temporary,
    additionalFormValues: {
      overwrite: 'true',
    },
    files: [file],
  });
  const responseJson = (await fileResponse.json()) as {
    id: number;
    path: string;
  };

  const { file_transfer_list } = await getFileList({
    id: responseJson.id,
    feature: FileTransferFeature.Temporary,
  });

  return file_transfer_list?.[0];
}
