import { useMemo } from 'react';
import {
  type AvailableLanguages,
  useCurrentLanguage,
} from './use-current-language';

export function useFormatNumber() {
  const { languageCode } = useCurrentLanguage();

  return useMemo(
    () => ({
      formatNumber: (
        value: number,
        options?: Intl.NumberFormatOptions & { language?: AvailableLanguages },
      ) => {
        const { language, ...intlOptions } = options ?? {};
        return new Intl.NumberFormat(
          language ?? languageCode,
          intlOptions,
        ).format(value);
      },
      formatPercent: (
        value: number,
        options?: Intl.NumberFormatOptions & { language?: AvailableLanguages },
      ) => {
        const { language, ...intlOptions } = options ?? {};
        return new Intl.NumberFormat(language ?? languageCode, {
          style: 'percent',
          maximumFractionDigits: 2,
          ...intlOptions,
        }).format(value / 100);
      },
      formatCurrency: (
        value: number,
        options?: Intl.NumberFormatOptions & { language?: AvailableLanguages },
      ) => {
        const { language, currency, ...intlOptions } = options ?? {};
        return new Intl.NumberFormat(language ?? languageCode, {
          style: 'currency',
          currency: currency || 'EUR',
          ...intlOptions,
        }).format(value);
      },
    }),
    [languageCode],
  );
}

export type ReturnTypeFromUseFormatNumber = ReturnType<typeof useFormatNumber>;
