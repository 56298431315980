import { PartyPersonType, type Person } from '../gql/graphql';

export function getPersonProfileLink(
  person: Pick<Person, 'type' | 'partyId'> | undefined,
  profileTab?: string | null,
) {
  const prefixUrl = {
    [PartyPersonType.Student]: 'students',
    [PartyPersonType.Staff]: 'staff',
    [PartyPersonType.Contact]: 'contacts',
  };

  if (!person?.type) return null;

  const personType = prefixUrl[person.type];
  const isHistoric = profileTab === 'historic-search';

  const baseUrl = isHistoric
    ? `/people/historic-search/${personType}`
    : `/people/${personType}`;

  return [baseUrl, person.partyId, isHistoric ? '' : profileTab]
    .filter(Boolean)
    .join('/');
}
