import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './user-group-presentation.svg';

export const UserGroupPresentationIcon = forwardRef<
  SVGSVGElement,
  SvgIconProps
>((props, ref) => (
  <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
));

if (process.env.NODE_ENV !== 'production') {
  UserGroupPresentationIcon.displayName = 'UserGroupPresentationIcon';
}
