import dayjs, { type Dayjs } from 'dayjs';

export function getDateFilterTableSettings() {
  return {
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: Dayjs) => {
        const filterDate = dayjs(filterLocalDateAtMidnight);

        if (cellValue.isSame(filterDate, 'day')) {
          return 0;
        }

        if (cellValue.isBefore(filterDate, 'day')) {
          return -1;
        }

        return 1;
      },
    },
  };
}
