import { Box, type BoxProps as MuiBoxProps } from '@mui/material';
import { useFloatCardPositioning } from './use-positioning';

export interface FloatCardDraggableContainerProps {
  ContainerProps?: MuiBoxProps;
  children?: React.ReactNode;
}

export function FloatCardDraggableContainer({
  children,
  ContainerProps,
}: FloatCardDraggableContainerProps) {
  const { moveHandlers, moveActive } = useFloatCardPositioning();

  return (
    <Box
      {...ContainerProps}
      {...moveHandlers}
      style={{
        cursor: moveActive ? 'grabbing' : 'grab',
        touchAction: 'none',
      }}
    >
      {children}
    </Box>
  );
}
