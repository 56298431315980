import { Card, type CardProps as MuiCardProps, useTheme } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import {
  FloatCardPositioningProvider,
  type InitialPosition,
} from './use-positioning';

interface FloatingCardProps {
  open: boolean;
  onClose: () => void;
  CardProps?: MuiCardProps;
  children?: React.ReactNode;
  initialWidth?: number;
  initialHeight?: number;
  initialPosition?: InitialPosition;
}

export function FloatingCard({
  open,
  onClose,
  CardProps,
  children,
  initialWidth = 400,
  initialHeight = 400,
  initialPosition,
}: FloatingCardProps) {
  const { zIndex } = useTheme();

  return (
    <FloatCardPositioningProvider
      open={open}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
      initialPosition={initialPosition}
    >
      {({ x, y, width, height, resizeHandlers, moveActive }) => (
        <AnimatePresence>
          {open && (
            <m.div
              initial={{ y: window.innerHeight, x, scale: 1 }}
              animate={{ y, x, scale: 1 }}
              exit={{ y, x, scale: 0 }}
              transition={{
                ease: moveActive ? 'linear' : 'easeInOut',
                duration: moveActive ? 0 : 0.3,
              }}
              style={{
                position: 'fixed',
                width,
                height,
                top: 0,
                left: 0,
                zIndex: zIndex.modal,
              }}
            >
              <Card
                {...CardProps}
                style={{ height: '100%' }}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                  }

                  CardProps?.onKeyDown?.(e);
                }}
              >
                {children}
              </Card>
              {resizeHandlers}
            </m.div>
          )}
        </AnimatePresence>
      )}
    </FloatCardPositioningProvider>
  );
}
