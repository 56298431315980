import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './invoice-accounting-document.svg';

export const InvoiceAccountingDocumentIcon = forwardRef<
  SVGSVGElement,
  SvgIconProps
>((props, ref) => (
  <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
));

if (process.env.NODE_ENV !== 'production') {
  InvoiceAccountingDocumentIcon.displayName = 'InvoiceAccountingDocumentIcon';
}
