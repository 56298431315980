import { Node, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addColumnCommands: {
      insertTwoColumnLayout: () => ReturnType;
      insertThreeColumnLayout: () => ReturnType;
      insertFourColumnLayout: () => ReturnType;
    };
  }
}

export const Column = Node.create({
  name: 'column',
  content: 'block+',
  defining: true,

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return { class: attributes.class };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.column',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'column',
      }),
      0,
    ];
  },
});

export const TwoColumnLayout = Node.create({
  name: 'twoColumnLayout',
  content: 'column{2}',
  group: 'block',
  inline: false,
  defining: true,

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return { class: attributes.class };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.two-column-layout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'two-column-layout' }),
      0,
    ];
  },

  addCommands() {
    return {
      insertTwoColumnLayout:
        () =>
        ({ commands }) => {
          const firstColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Left column' }],
              },
            ],
            attrs: {
              style: 'text-align: left;',
            },
          };

          const secondColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Right column' }],
              },
            ],
            attrs: {
              style: 'text-align: right;',
            },
          };

          const twoColumnLayoutNode = {
            type: 'twoColumnLayout',
            content: [firstColumnNode, secondColumnNode],
          };
          return commands.insertContent(twoColumnLayoutNode);
        },
    };
  },
});

export const ThreeColumnLayout = Node.create({
  name: 'threeColumnLayout',
  content: 'column{3}',
  group: 'block',
  inline: false,
  defining: true,

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return { class: attributes.class };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.three-column-layout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'three-column-layout' }),
      0,
    ];
  },

  addCommands() {
    return {
      insertThreeColumnLayout:
        () =>
        ({ commands }) => {
          const firstColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: left;',
            },
          };

          const secondColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: center;',
            },
          };

          const thirdColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: right;',
            },
          };

          const threeColumnLayoutNode = {
            type: 'threeColumnLayout',
            content: [firstColumnNode, secondColumnNode, thirdColumnNode],
          };

          return commands.insertContent(threeColumnLayoutNode);
        },
    };
  },
});

export const FourColumnLayout = Node.create({
  name: 'fourColumnLayout',
  content: 'column{4}',
  group: 'block',
  inline: false,
  defining: true,

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return { class: attributes.class };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.four-column-layout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'four-column-layout' }),
      0,
    ];
  },

  addCommands() {
    return {
      insertFourColumnLayout:
        () =>
        ({ commands }) => {
          const firstColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: left;',
            },
          };

          const secondColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: center;',
            },
          };

          const thirdColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: center;',
            },
          };

          const fourthColumnNode = {
            type: 'column',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'column' }],
              },
            ],
            attrs: {
              style: 'text-align: right;',
            },
          };

          const fourColumnLayoutNode = {
            type: 'fourColumnLayout',
            content: [
              firstColumnNode,
              secondColumnNode,
              thirdColumnNode,
              fourthColumnNode,
            ],
          };

          return commands.insertContent(fourColumnLayoutNode);
        },
    };
  },
});
