import {
  Box,
  type BoxProps,
  Button,
  Stack,
  type StackProps,
} from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import { useMemo, useState } from 'react';
import { useDisclosure } from '../../hooks';

interface LineClampContainerProps extends StackProps {
  numberOfLines: number;
  lineHeight: number;
  allowExpand?: boolean;
  TextContainerProps?: BoxProps;
}

export function LineClampContainer({
  numberOfLines,
  lineHeight,
  allowExpand,
  children,
  TextContainerProps,
  ...props
}: LineClampContainerProps) {
  const { t } = useTranslation(['common']);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { isOpen, onToggle } = useDisclosure();

  const collapseHeight = lineHeight * numberOfLines;

  const isEllipsisActive = useMemo(() => {
    if (!ref) return false;

    return (
      ref.scrollHeight > ref.offsetHeight || ref.scrollHeight > collapseHeight
    );
  }, [ref, collapseHeight]);

  return (
    <Stack alignItems="flex-start" {...props}>
      <Box
        {...TextContainerProps}
        ref={(el) => setRef(el as HTMLDivElement)}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: isOpen ? 'initial' : numberOfLines,
          maxHeight: isOpen ? 'auto' : collapseHeight,
          ...props?.sx,
        }}
      >
        {children}
      </Box>
      {allowExpand && isEllipsisActive && (
        <Button
          variant="text"
          size="small"
          onClick={onToggle}
          sx={{
            py: 0,
            transform: 'translateX(-5px)',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {isOpen ? t('common:readLess') : t('common:readMore')}
        </Button>
      )}
    </Stack>
  );
}
