import type { CustomCellEditorProps } from '@ag-grid-community/react';
import {
  ClickAwayListener,
  Divider,
  MenuItem,
  MenuList,
  type TooltipProps as MuiTooltipProps,
  Tooltip,
} from '@mui/material';
import type React from 'react';
import { forwardRef, useCallback } from 'react';

export interface OptionProps {
  disabled?: boolean;
  disabledTooltip?: string;
  TooltipProps?: MuiTooltipProps;
  [key: string]: unknown;
}

export interface TableSelectProps<TSelectOption extends OptionProps>
  extends CustomCellEditorProps<
    unknown,
    string | number | (string | number)[]
  > {
  options: TSelectOption[] | TSelectOption[][];
  getOptionLabel?: (option: TSelectOption) => string;
  renderOption?: (option: TSelectOption) => React.ReactNode;
  optionIdKey?: TSelectOption extends string | number
    ? never
    : keyof TSelectOption;
}

function checkTableSelectorProps<TSelectOption extends OptionProps>(
  props: TableSelectProps<TSelectOption>,
): asserts props is TableSelectProps<TSelectOption> {
  if (process.env.NODE_ENV !== 'production') {
    if (!Array.isArray(props.options)) {
      throw new Error(
        `Please provide an array of options to cellEditorSelector.params.options for the TableSelect component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }

    if (
      typeof props.getOptionLabel !== 'function' &&
      typeof props.renderOption !== 'function'
    ) {
      throw new Error(
        `Please provide a getOptionLabel or renderOption function to cellEditorSelector.params.getOptionLabel/renderOption for the TableSelect component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }

    if (!props.optionIdKey) {
      throw new Error(
        `Please provide a optionIdKey to cellEditorSelector.params.optionIdKey for the TableSelect component in the ${
          props?.colDef?.headerName ?? ''
        } column`,
      );
    }
  }
}

export function TableSelect<TSelectOption extends OptionProps>(
  props: TableSelectProps<TSelectOption>,
) {
  const {
    value,
    onValueChange,
    stopEditing,
    options = [],
    optionIdKey,
    getOptionLabel,
    renderOption,
  } = props;

  const isMultiple = Array.isArray(value);

  const onClick = useCallback(
    (optionValue: string | number) => {
      if (isMultiple) {
        const newValue = value.includes(optionValue)
          ? value.filter((v) => v !== optionValue)
          : [...value, optionValue];
        onValueChange(newValue);
      } else {
        onValueChange(value === optionValue ? null : optionValue);
        stopEditing(false);
      }
    },
    [value, onValueChange, isMultiple],
  );

  const isOptionSelected = useCallback(
    (optionValue: string | number) => {
      return isMultiple ? value.includes(optionValue) : value === optionValue;
    },
    [value, isMultiple],
  );

  const getOption = useCallback(
    (option: TSelectOption) => {
      const value = optionIdKey
        ? (option[optionIdKey] as string)
        : String(option);
      const { disabled, disabledTooltip, TooltipProps } = option;

      const menuItem = (
        <MenuItem
          key={value}
          value={value}
          selected={isOptionSelected(value)}
          autoFocus={isOptionSelected(value)}
          disabled={disabled}
          onClick={() => onClick(value)}
        >
          {renderOption?.(option)}
          {getOptionLabel?.(option)}
        </MenuItem>
      );

      return disabled && disabledTooltip ? (
        <Tooltip title={disabledTooltip} {...TooltipProps}>
          <span>{menuItem}</span>
        </Tooltip>
      ) : (
        menuItem
      );
    },
    [isMultiple, value, stopEditing, optionIdKey, getOptionLabel, renderOption],
  );

  checkTableSelectorProps(props);

  return (
    <ClickAwayListener onClickAway={() => stopEditing(false)}>
      <MenuList
        autoFocus={!value}
        sx={{
          maxHeight: '50vh',
          overflowY: 'auto',
          backgroundColor: 'background.paper',
        }}
      >
        {options?.map((option, index) => {
          if (Array.isArray(option)) {
            return [
              index > 0 && <Divider />,
              option.map((singleOption) => getOption(singleOption)),
            ];
          }

          return getOption(option);
        })}
      </MenuList>
    </ClickAwayListener>
  );
}
