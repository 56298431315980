import type { PopoverProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

const defaultPopoverOffset = 6;

// anchorOrigin.vertical-anchorOrigin.horizontal-transformOrigin.vertical-transformOrigin.horizontal
const popoverOffsetMap = new Map([
  ['top-left-bottom-left', { marginTop: -defaultPopoverOffset }],
  ['top-right-bottom-right', { marginTop: -defaultPopoverOffset }],
  ['top-center-bottom-center', { marginTop: -defaultPopoverOffset }],
  ['top-right-top-left', { marginLeft: defaultPopoverOffset }],
  ['bottom-right-bottom-left', { marginLeft: defaultPopoverOffset }],
  ['center-right-center-left', { marginLeft: defaultPopoverOffset }],
  ['bottom-right-top-right', { marginTop: defaultPopoverOffset }],
  ['bottom-left-top-left', { marginTop: defaultPopoverOffset }],
  ['bottom-center-top-center', { marginTop: defaultPopoverOffset }],
  ['bottom-left-bottom-right', { marginLeft: -defaultPopoverOffset }],
  ['top-left-top-right', { marginLeft: -defaultPopoverOffset }],
  ['center-left-center-right', { marginLeft: -defaultPopoverOffset }],
]);

export default function Popover(theme: Theme) {
  const getPopoverOffset = (props: PopoverProps) => {
    const { anchorReference, anchorOrigin, transformOrigin } = props ?? {};
    if (anchorReference === 'anchorPosition') {
      return {};
    }

    const resolvedAnchorOrigin = anchorOrigin ?? {
      vertical: 'bottom',
      horizontal: 'left',
    };
    const resolvedTransformOrigin = transformOrigin ?? {
      vertical: 'top',
      horizontal: 'left',
    };

    return popoverOffsetMap.get(
      `${resolvedAnchorOrigin.vertical}-${resolvedAnchorOrigin.horizontal}-${resolvedTransformOrigin.vertical}-${resolvedTransformOrigin.horizontal}`,
    );
  };

  return {
    MuiPopover: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: PopoverProps }) => ({
          ...getPopoverOffset(ownerState),
        }),
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
        },
      },
    },
  };
}
