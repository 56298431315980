import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useTranslation } from '@tyro/i18n';
import {
  AddColumnIcon,
  FourColumnsIcon,
  ThreeColumnsIcon,
  TwoColumnsIcon,
} from '@tyro/icons';
import { useState } from 'react';
import { ToggleButton } from './toolbar-buttons/toggle-button';

interface MenuToolBarProps {
  editor: Editor | null;
}

export default function AddColumnsMenu({ editor }: MenuToolBarProps) {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ('key' in event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ToggleButton
        title={t('common:tooltipTitles.insertColumns')}
        onClick={(event) => handleMenuOpen(event)}
        value="insertColumns"
      >
        <AddColumnIcon />
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,

            '& .MuiMenuItem-root': {
              paddingX: 1,
              margin: 0,
            },
          },
        }}
      >
        <Typography paddingX={1} pt={1} fontSize="14px">
          {t('common:tooltipTitles.addColumns')}
        </Typography>
        <Stack flexDirection="row">
          {[
            {
              id: 'twoColumn',
              icon: <TwoColumnsIcon />,
              action: () => {
                editor?.commands.insertTwoColumnLayout();
                setAnchorEl(null);
              },
            },
            {
              id: 'threeColumn',
              icon: <ThreeColumnsIcon />,
              action: () => {
                editor?.commands.insertThreeColumnLayout();
                setAnchorEl(null);
              },
            },
            {
              id: 'fourColumn',
              icon: <FourColumnsIcon />,
              action: () => {
                editor?.commands.insertFourColumnLayout();
                setAnchorEl(null);
              },
            },
          ].map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              sx={{ paddingX: 1 }}
              onClick={menuItem.action}
            >
              {menuItem.icon}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}
