import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './zoom-minus.svg';

export const ZoomMinusIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
  ),
);

if (process.env.NODE_ENV !== 'production') {
  ZoomMinusIcon.displayName = 'ZoomMinusIcon';
}
