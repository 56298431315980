import { nanoid } from 'nanoid';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FabMenu, type FabMenuProps } from './component';

export type { FabMenuProps } from './component';

export type FabMenuContextValue = {
  setFabMenuSettings: (settings: FabMenuProps) => () => void;
};

export const FabMenuContext = createContext<FabMenuContextValue | undefined>(
  undefined,
);

type FabProviderProps = {
  children: ReactNode;
};

export function FabMenuProvider({ children }: FabProviderProps) {
  const [currentFabSettings, setCurrentFabSettings] = useState<{
    id: string;
    settings: FabMenuProps;
  } | null>(null);

  const setFabMenuSettings = useCallback(
    (settings: FabMenuProps) => {
      const id = nanoid(6);
      setCurrentFabSettings({ id, settings });
      return () =>
        setCurrentFabSettings((currentState) =>
          currentState?.id === id ? null : currentState,
        );
    },
    [setCurrentFabSettings],
  );

  const value = useMemo(
    () => ({
      setFabMenuSettings,
    }),
    [setFabMenuSettings],
  );
  return (
    <>
      <FabMenuContext.Provider value={value}>
        {children}
      </FabMenuContext.Provider>
      <FabMenu {...currentFabSettings?.settings} />
    </>
  );
}

export function useFabMenu() {
  const context = useContext(FabMenuContext);
  if (context === undefined) {
    throw new Error('useFabMenu must be used within a FabMenuContext');
  }
  return context;
}
