import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './email-stamp.svg';

export const EmailStampIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
  ),
);

if (process.env.NODE_ENV !== 'production') {
  EmailStampIcon.displayName = 'EmailStampIcon';
}
