import {
  IconButton,
  type IconButtonProps,
  Tooltip,
  type TooltipProps,
} from '@mui/material';
import { forwardRef } from 'react';

interface IconButtonWithTooltipProps extends IconButtonProps {
  title: string;
  placement?: TooltipProps['placement'];
}

export const IconButtonWithTooltip = forwardRef<
  HTMLButtonElement,
  IconButtonWithTooltipProps
>(({ title, placement = 'bottom', disabled, ...props }, ref) => {
  const icon = (
    <IconButton ref={ref} size="small" disabled={disabled} {...props} />
  );

  return (
    <Tooltip title={title} placement={placement} enterDelay={500}>
      {disabled ? <span>{icon}</span> : icon}
    </Tooltip>
  );
});

if (process.env.NODE_ENV !== 'production') {
  IconButtonWithTooltip.displayName = 'IconButtonWithTooltip';
}
