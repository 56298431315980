/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box } from '@mui/material';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type ResizeType =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left';

export type InitialPosition =
  | { top: number; left: number }
  | { top: number; right: number }
  | { bottom: number; left: number }
  | { bottom: number; right: number };

export type FloatCardPositioningContextValue = {
  width: number;
  height: number;
  x: number;
  y: number;
  resizeHandlers: JSX.Element;
  moveHandlers: {
    onMouseDown: (e: React.MouseEvent) => void;
    onTouchStart: (e: React.TouchEvent) => void;
  };
  moveActive: boolean;
};

const FloatCardPositioningContext = createContext<
  FloatCardPositioningContextValue | undefined
>(undefined);

type FloatCardPositioningProviderProps = {
  open: boolean;
  initialWidth?: number;
  initialHeight?: number;
  initialPosition?: InitialPosition;
  children:
    | ReactNode
    | ((value: FloatCardPositioningContextValue) => ReactNode);
};

function getInitialConfig(
  initialWidth: number,
  initialHeight: number,
  initialPosition: InitialPosition,
) {
  const windowWidth = Math.min(initialWidth, window.innerWidth - 16);
  const windowHeight = Math.min(initialHeight, window.innerHeight - 16);

  let x = 0;
  let y = 0;

  if ('top' in initialPosition) {
    y = initialPosition.top;
  } else if ('bottom' in initialPosition) {
    y = window.innerHeight - windowHeight - initialPosition.bottom;
  }

  if ('left' in initialPosition) {
    x = initialPosition.left;
  } else if ('right' in initialPosition) {
    x = window.innerWidth - windowWidth - initialPosition.right;
  }

  return {
    width: windowWidth,
    height: windowHeight,
    x,
    y,
  };
}

export function FloatCardPositioningProvider({
  open,
  initialWidth = 400,
  initialHeight = 200,
  initialPosition = { top: 16, left: 16 },
  children,
}: FloatCardPositioningProviderProps) {
  const mouseMoveFuncReference = useRef<((e: MouseEvent) => void) | null>(null);
  const touchMoveFuncReference = useRef<((e: TouchEvent) => void) | null>(null);
  const lastTouchReference = useRef<{ x: number; y: number } | null>(null);
  const [moveActive, setMoveActive] = useState(false);
  const [positioningConfig, setPositioningConfig] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  }>(getInitialConfig(initialWidth, initialHeight, initialPosition));
  const [activeResizeType, setActiveResizeType] = useState<
    [ResizeType, 'mouse' | 'touch'] | null
  >(null);

  const resizeHandlers = useMemo(
    () => (
      <>
        {/* Side drag bars */}
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            left: 0,
            cursor: 'ns-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            height: 8,
            top: -4,
            '@media (pointer:coarse)': {
              height: 16,
              top: -8,
            },
          }}
          onMouseDown={() => setActiveResizeType(['top', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['top', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            cursor: 'ew-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 8,
            right: -4,
            '@media (pointer:coarse)': {
              width: 16,
              right: -8,
            },
          }}
          onMouseDown={() => setActiveResizeType(['right', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['right', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            left: 0,
            cursor: 'ns-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            height: 8,
            bottom: -4,
            '@media (pointer:coarse)': {
              height: 16,
              bottom: -8,
            },
          }}
          onMouseDown={() => setActiveResizeType(['bottom', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['bottom', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            cursor: 'ew-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 8,
            left: -4,
            '@media (pointer:coarse)': {
              width: 16,
              left: -8,
            },
          }}
          onMouseDown={() => setActiveResizeType(['left', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['left', 'touch']);
          }}
        />
        {/* Corner drag bars */}
        <Box
          style={{
            position: 'absolute',
            cursor: 'nwse-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 16,
            height: 16,
            top: -4,
            left: -4,
            '@media (pointer:coarse)': {
              width: 20,
              height: 20,
              top: -6,
              left: -6,
            },
          }}
          onMouseDown={() => setActiveResizeType(['top-left', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['top-left', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            cursor: 'nesw-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 16,
            height: 16,
            top: -4,
            right: -4,
            '@media (pointer:coarse)': {
              width: 20,
              height: 20,
              top: -6,
              right: -6,
            },
          }}
          onMouseDown={() => setActiveResizeType(['top-right', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['top-right', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            cursor: 'nesw-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 16,
            height: 16,
            bottom: -4,
            left: -4,
            '@media (pointer:coarse)': {
              width: 20,
              height: 20,
              bottom: -6,
              left: -6,
            },
          }}
          onMouseDown={() => setActiveResizeType(['bottom-left', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['bottom-left', 'touch']);
          }}
        />
        <Box
          style={{
            position: 'absolute',
            cursor: 'nwse-resize',
            userSelect: 'none',
            touchAction: 'none',
          }}
          sx={{
            width: 16,
            height: 16,
            bottom: -4,
            right: -4,
            '@media (pointer:coarse)': {
              width: 16,
              height: 16,
              bottom: -6,
              right: -6,
            },
          }}
          onMouseDown={() => setActiveResizeType(['bottom-right', 'mouse'])}
          onTouchStart={(e) => {
            lastTouchReference.current = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            };
            setActiveResizeType(['bottom-right', 'touch']);
          }}
        />
      </>
    ),
    [setActiveResizeType],
  );

  const moveHandlers = useMemo(
    () => ({
      onMouseDown: (e: React.MouseEvent) => {
        if (e.ctrlKey || e.button !== 0) return;
        e.preventDefault();
        setMoveActive(true);

        mouseMoveFuncReference.current = (event: MouseEvent) => {
          setPositioningConfig((previousConfig) => {
            const x = Math.max(
              0,
              Math.min(
                previousConfig.x + event.movementX,
                window.innerWidth - previousConfig.width,
              ),
            );
            const y = Math.max(
              0,
              Math.min(
                previousConfig.y + event.movementY,
                window.innerHeight - previousConfig.height,
              ),
            );

            return {
              ...previousConfig,
              x,
              y,
            };
          });
        };

        const resetMouseMove = () => {
          if (mouseMoveFuncReference.current) {
            window.removeEventListener(
              'mousemove',
              mouseMoveFuncReference.current,
            );
            mouseMoveFuncReference.current = null;
            setMoveActive(false);
          }
        };

        window.addEventListener('mousemove', mouseMoveFuncReference.current);
        window.addEventListener('mouseup', resetMouseMove);
      },
      onTouchStart: (e: React.TouchEvent) => {
        setMoveActive(true);

        lastTouchReference.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY,
        };

        touchMoveFuncReference.current = (event: TouchEvent) => {
          event.preventDefault();
          setPositioningConfig((previousConfig) => {
            if (!lastTouchReference.current) return previousConfig;

            const touch = event.touches[0];
            const x = Math.max(
              0,
              Math.min(
                previousConfig.x + touch.clientX - lastTouchReference.current.x,
                window.innerWidth - previousConfig.width,
              ),
            );
            const y = Math.max(
              0,
              Math.min(
                previousConfig.y + touch.clientY - lastTouchReference.current.y,
                window.innerHeight - previousConfig.height,
              ),
            );

            lastTouchReference.current = {
              x: touch.clientX,
              y: touch.clientY,
            };

            return {
              ...previousConfig,
              x,
              y,
            };
          });
        };

        const resetTouchMove = () => {
          if (touchMoveFuncReference.current) {
            window.removeEventListener(
              'touchmove',
              touchMoveFuncReference.current,
            );
            touchMoveFuncReference.current = null;
            lastTouchReference.current = null;
            setMoveActive(false);
          }
        };

        window.addEventListener('touchmove', touchMoveFuncReference.current);
        window.addEventListener('touchend', resetTouchMove);
      },
    }),
    [positioningConfig, setPositioningConfig, setMoveActive],
  );

  useEffect(() => {
    const maxWidth = window.innerWidth - 16;
    const maxHeight = window.innerHeight - 16;
    const minSize = 60;

    setMoveActive(true);

    const resetActiveResizeType = () => {
      setActiveResizeType(null);
      setMoveActive(false);
    };

    if (activeResizeType && activeResizeType[1] === 'mouse') {
      mouseMoveFuncReference.current = (e: MouseEvent) => {
        setPositioningConfig((prevConfig) => {
          let { width, height, x, y } = prevConfig;

          if (activeResizeType[0].includes('top')) {
            const originalHeight = height;
            height = Math.min(maxHeight, height - e.movementY);

            if (height < minSize) {
              height = minSize;
              y = originalHeight - minSize + y;
            } else {
              const proposedY = y + e.movementY;
              y = Math.max(0, Math.min(proposedY, maxHeight - height));
            }
          }

          if (activeResizeType[0].includes('right')) {
            width = Math.max(minSize, Math.min(maxWidth, width + e.movementX));
          }

          if (activeResizeType[0].includes('bottom')) {
            height = Math.max(
              minSize,
              Math.min(maxHeight, height + e.movementY),
            );
          }

          if (activeResizeType[0].includes('left')) {
            const originalWidth = width;
            width = Math.min(maxWidth, width - e.movementX);

            if (width <= minSize) {
              width = minSize;
              x = originalWidth - minSize + x;
            } else {
              const proposedX = x + e.movementX;
              x = Math.max(0, Math.min(proposedX, maxWidth - width));
            }
          }

          return {
            width,
            height,
            x,
            y,
          };
        });
      };

      window.addEventListener('mousemove', mouseMoveFuncReference.current);
      window.addEventListener('mouseup', resetActiveResizeType);

      return () => {
        if (mouseMoveFuncReference.current) {
          window.removeEventListener(
            'mousemove',
            mouseMoveFuncReference.current,
          );
          mouseMoveFuncReference.current = null;
        }
        window.removeEventListener('mouseup', resetActiveResizeType);
      };
    }

    if (activeResizeType && activeResizeType[1] === 'touch') {
      touchMoveFuncReference.current = (e: TouchEvent) => {
        setPositioningConfig((prevConfig) => {
          let { width, height, x, y } = prevConfig;

          const touch = e.touches[0];
          if (!lastTouchReference.current) return prevConfig;

          const { x: lastTouchX, y: lastTouchY } = lastTouchReference.current;
          const movementX = touch.clientX - lastTouchX;
          const movementY = touch.clientY - lastTouchY;

          if (activeResizeType[0].includes('top')) {
            const originalHeight = height;
            height = Math.min(maxHeight, height - movementY);

            if (height < minSize) {
              height = minSize;
              y = originalHeight - minSize + y;
            } else {
              const proposedY = y + movementY;
              y = Math.max(0, Math.min(proposedY, maxHeight - height));
            }
          }

          if (activeResizeType[0].includes('right')) {
            width = Math.max(minSize, Math.min(maxWidth, width + movementX));
          }

          if (activeResizeType[0].includes('bottom')) {
            height = Math.max(minSize, Math.min(maxHeight, height + movementY));
          }

          if (activeResizeType[0].includes('left')) {
            const originalWidth = width;
            width = Math.min(maxWidth, width - movementX);

            if (width <= minSize) {
              width = minSize;
              x = originalWidth - minSize + x;
            } else {
              const proposedX = x + movementX;
              x = Math.max(0, Math.min(proposedX, maxWidth - width));
            }
          }

          lastTouchReference.current = {
            x: touch.clientX,
            y: touch.clientY,
          };

          return {
            width,
            height,
            x,
            y,
          };
        });
      };

      window.addEventListener('touchmove', touchMoveFuncReference.current);
      window.addEventListener('touchend', resetActiveResizeType);

      return () => {
        if (touchMoveFuncReference.current) {
          window.removeEventListener(
            'touchmove',
            touchMoveFuncReference.current,
          );
          touchMoveFuncReference.current = null;
          lastTouchReference.current = null;
        }
        window.removeEventListener('touchend', resetActiveResizeType);
      };
    }

    if (mouseMoveFuncReference.current) {
      window.removeEventListener('mousemove', mouseMoveFuncReference.current);
      mouseMoveFuncReference.current = null;
    }

    if (touchMoveFuncReference.current) {
      window.removeEventListener('touchmove', touchMoveFuncReference.current);
      touchMoveFuncReference.current = null;
      lastTouchReference.current = null;
    }
  }, [activeResizeType, setPositioningConfig]);

  useEffect(() => {
    const handleResize = () => {
      setPositioningConfig((prevConfig) => {
        const maxWidth = window.innerWidth - 16;
        const maxHeight = window.innerHeight - 16;

        return {
          width: Math.min(maxWidth, prevConfig.width),
          height: Math.min(maxHeight, prevConfig.height),
          x: Math.max(0, Math.min(prevConfig.x, maxWidth - prevConfig.width)),
          y: Math.max(0, Math.min(prevConfig.y, maxHeight - prevConfig.height)),
        };
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setPositioningConfig]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setPositioningConfig(
          getInitialConfig(initialWidth, initialHeight, initialPosition),
        );
      }, 500);
    }
  }, [open, initialWidth, initialHeight, initialPosition]);

  const value = useMemo<FloatCardPositioningContextValue>(
    () => ({
      ...positioningConfig,
      resizeHandlers,
      moveHandlers,
      moveActive,
    }),
    [positioningConfig, resizeHandlers, moveHandlers, moveActive],
  );
  return (
    <FloatCardPositioningContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </FloatCardPositioningContext.Provider>
  );
}

export function useFloatCardPositioning() {
  const context = useContext(FloatCardPositioningContext);
  if (context === undefined) {
    throw new Error(
      '<FloatCardDraggableContainer /> must be a child of a <FloatingCard /> component.',
    );
  }
  return context;
}
