import { Tab, type TabProps } from '@mui/material';
import { Link } from 'react-router-dom';

export interface LinkTabProps {
  label: string;
  to: string;
  value: TabProps['value'];
  icon?: TabProps['icon'];
}

export function LinkTab(props: LinkTabProps) {
  return <Tab component={Link} {...props} />;
}
