import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './excel.svg';

export const ExcelIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
  ),
);

if (process.env.NODE_ENV !== 'production') {
  ExcelIcon.displayName = 'ExcelIcon';
}
