import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useTranslation } from '@tyro/i18n';
import { ChevronDownIcon } from '@tyro/icons';
import { type KeyboardEvent, type MouseEvent, useState } from 'react';

interface FontPickerProps {
  editor: Editor | null;
}

type Fonts = {
  fontStyle: string;
  fontFamily: string;
};

const fonts: Fonts[] = [
  { fontStyle: 'Sans serif', fontFamily: 'Arial' },
  { fontStyle: ' Serif', fontFamily: 'Times New Roman' },
];

export default function FontPicker({ editor }: FontPickerProps) {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fontPlaceHolder, setFontPlaceHolder] = useState(fonts[0]?.fontStyle);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ) => {
    if ('key' in event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFontChange = (font: Fonts) => {
    if (editor) {
      editor.chain().setFontFamily(font?.fontFamily).run();
      setFontPlaceHolder(font?.fontStyle);
    }
    handleMenuClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
      }}
    >
      <Tooltip title={t('common:tooltipTitles.font')} placement="bottom">
        <Box
          role="button"
          tabIndex={0}
          aria-haspopup="true"
          id="dropdown-button"
          aria-controls="dropdown-menu"
          onClick={handleMenuOpen}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            cursor: 'pointer',
          }}
        >
          <Typography fontSize="14px">{fontPlaceHolder}</Typography>
          <ChevronDownIcon />
        </Box>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {fonts.map((font) => (
          <MenuItem
            key={font?.fontFamily}
            onClick={() => {
              handleFontChange(font);
            }}
          >
            {font?.fontStyle}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
