import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { FilledInput, FormLabel, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

export function TableHourAndMin(props: CustomCellEditorProps<unknown, number>) {
  const propValue = props.value ?? 0;
  const [hour, setHour] = useState<string>(String(Math.floor(propValue / 60)));
  const [minute, setMinute] = useState<string>(String(propValue % 60));

  const getValue = (newValue: string, oldValue: string) => {
    const trimmedValue = newValue.trim();
    if (trimmedValue === '') return '';

    if (!Number.isNaN(Number(trimmedValue))) return trimmedValue;

    return oldValue;
  };

  useEffect(() => {
    props.onValueChange(
      Number.parseInt(hour || '0') * 60 + Number.parseInt(minute || '0'),
    );
  }, [hour, minute]);

  return (
    <Stack direction="row" spacing={2} p={1} bgcolor="white">
      <Stack direction="row" spacing={1} alignItems="center">
        <FilledInput
          value={hour}
          onChange={(event) => {
            setHour((prev) => getValue(event.target.value, prev));
          }}
          hiddenLabel
          size="small"
          sx={{
            maxWidth: 60,
          }}
        />
        <FormLabel>h</FormLabel>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <FilledInput
          value={minute}
          onChange={(event) => {
            setMinute((prev) => getValue(event.target.value, prev));
          }}
          hiddenLabel
          size="small"
          sx={{
            maxWidth: 60,
          }}
        />
        <FormLabel>m</FormLabel>
      </Stack>
    </Stack>
  );
}
