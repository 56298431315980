type RGB = [number, number, number];

export function mixHexColors(
  color1: string,
  color2: string,
  ratio = 0.5,
): string {
  // Ensure ratio is between 0 and 1
  const clampedRatio = Math.min(1, Math.max(0, ratio));

  const rgb1: RGB = hexToRgb(color1);
  const rgb2: RGB = hexToRgb(color2);

  const mixed: RGB = rgb1.map((channel, i) =>
    Math.round(channel * (1 - clampedRatio) + rgb2[i] * clampedRatio),
  ) as RGB;

  return rgbToHex(mixed);
}

function hexToRgb(hex: string): RGB {
  // Remove # if present
  const cleanHex = hex.replace(/^#/, '');

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const fullHex =
    cleanHex.length === 3
      ? cleanHex
          .split('')
          .map((char) => char + char)
          .join('')
      : cleanHex;

  const bigint = Number.parseInt(fullHex, 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

function rgbToHex(rgb: RGB): string {
  return `#${rgb
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
}
