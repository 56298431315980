import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './eye-slash.svg';

export const EyeSlashIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
  ),
);

if (process.env.NODE_ENV !== 'production') {
  EyeSlashIcon.displayName = 'EyeSlashIcon';
}
