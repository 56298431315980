import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { FileTransferFeature, fetchClient } from '@tyro/api';

export interface UseUploadFileProps {
  documentType: FileTransferFeature;
  additionalFormValues?: Record<string, string | Blob>;
  mutationOptions?: Omit<
    UseMutationOptions<unknown, Error, File[]>,
    'mutationFn'
  >;
}

export function uploadFile({
  documentType,
  additionalFormValues,
  files,
}: Omit<UseUploadFileProps, 'mutationOptions'> & { files: File[] }) {
  const formData = new FormData();
  formData.append('file', files[0]);

  if (additionalFormValues) {
    for (const [key, value] of Object.entries(additionalFormValues)) {
      formData.append(key, value);
    }
  }

  return fetchClient(`/api/file-transfer/${documentType}`, {
    method: 'POST',
    bodyType: 'formData',
    body: formData,
  }) as Promise<Response>;
}

export function useUploadFile(
  {
    documentType = FileTransferFeature.StudentDocs,
    additionalFormValues,
    mutationOptions,
  }: UseUploadFileProps = {
    documentType: FileTransferFeature.StudentDocs,
  },
) {
  return useMutation({
    mutationFn: (files: File[]) =>
      uploadFile({ documentType, additionalFormValues, files }),
    ...mutationOptions,
  });
}
