const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

type SortableItem = string | number | null | undefined;

export function naturalSort<T extends SortableItem>(a: T, b: T) {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  const aStr = String(a);
  const bStr = String(b);
  const aParts = aStr.match(/(\d+|\D+)/g) || [];
  const bParts = bStr.match(/(\d+|\D+)/g) || [];

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const aNum = Number(aParts[i]);
    const bNum = Number(bParts[i]);
    const aIsNum = !Number.isNaN(aNum);
    const bIsNum = !Number.isNaN(bNum);

    if (aIsNum && bIsNum) {
      const diff = Number(aParts[i]) - Number(bParts[i]);
      if (diff !== 0) return diff;
    } else if (aIsNum !== bIsNum) {
      return aIsNum ? -1 : 1;
    } else {
      const compare = collator.compare(aParts[i], bParts[i]);
      if (compare !== 0) return compare;
    }
  }

  return aParts.length - bParts.length;
}
