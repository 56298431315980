import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

import { ReactComponent } from './email-newsletter.svg';

export const EmailNewsletterIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <SvgIcon ref={ref} component={ReactComponent} inheritViewBox {...props} />
  ),
);

if (process.env.NODE_ENV !== 'production') {
  EmailNewsletterIcon.displayName = 'EmailNewsletterIcon';
}
