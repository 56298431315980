import { gqlClient } from '../clients';
import { graphql } from '../gql';

const resetDefaultProfileMutation = graphql(/* GraphQL */ `
  mutation users_logout {
    users_logout {
      success
    }
  }
`);

export function resetDefaultProfile() {
  return gqlClient.request(resetDefaultProfileMutation);
}
