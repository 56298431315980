import { useEffect, useState } from 'react';

type RGB = {
  r: number;
  g: number;
  b: number;
};

function parseRGB(color: string): RGB | null {
  const match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (match) {
    return {
      r: Number.parseInt(match[1], 10),
      g: Number.parseInt(match[2], 10),
      b: Number.parseInt(match[3], 10),
    };
  }
  return null;
}

function calculateLuminance({ r, g, b }: RGB): number {
  const a = [r, g, b].map((v) => {
    const modifiedV = v / 255;
    return modifiedV <= 0.03928
      ? modifiedV / 12.92
      : ((modifiedV + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function determineContrastClass(bgColor: RGB): 'light' | 'dark' {
  const luminance = calculateLuminance(bgColor);
  return luminance > 0.179 ? 'light' : 'dark';
}

async function getAverageColor(imageUrl: string): Promise<RGB | null> {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        resolve(null);
        return;
      }
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      let r = 0;
      let g = 0;
      let b = 0;

      for (let i = 0; i < data.length; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
      }

      const pixelCount = data.length / 4;
      r = Math.floor(r / pixelCount);
      g = Math.floor(g / pixelCount);
      b = Math.floor(b / pixelCount);

      resolve({ r, g, b });
    };
    img.onerror = () => resolve(null);
    img.src = imageUrl;
  });
}

export async function getContrastClass(
  backgroundColor?: string,
  backgroundImage?: string,
  fallback?: 'light' | 'dark',
): Promise<'light' | 'dark'> {
  if (backgroundColor) {
    const rgb = parseRGB(backgroundColor);
    if (rgb) {
      return determineContrastClass(rgb);
    }
  }

  if (backgroundImage) {
    const avgColor = await getAverageColor(backgroundImage);
    if (avgColor) {
      return determineContrastClass(avgColor);
    }
  }

  // Fallback to 'light' if no valid input is provided
  return fallback ?? 'light';
}

export function useContrastClass(
  backgroundColor?: string,
  backgroundImage?: string,
  fallback?: 'light' | 'dark',
): 'light' | 'dark' {
  const [contrastClass, setContrastClass] = useState<'light' | 'dark'>(
    fallback ?? 'light',
  );

  useEffect(() => {
    getContrastClass(backgroundColor, backgroundImage, fallback).then(
      setContrastClass,
    );
  }, [backgroundColor, backgroundImage, fallback]);

  return contrastClass;
}
