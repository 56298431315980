import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { Switch } from '@mui/material';

export function TableSwitch({
  value,
  onValueChange,
}: CustomCellEditorProps<unknown, boolean>) {
  return (
    <Switch
      autoFocus
      defaultChecked={value ?? undefined}
      onChange={(event) => onValueChange(event.target.checked)}
    />
  );
}
