import { Box, type BoxProps } from '@mui/material';
import ApexChart from 'react-apexcharts';
import type { ChartProps } from './types';
import './styles.css';
import { LoadingPlaceholderContainer } from '../loading-placeholder';

export function Chart({
  sx,
  type,
  series,
  height,
  options,
  width = '100%',
  isLoading = false,
  ...other
}: BoxProps & ChartProps & { isLoading?: boolean }) {
  return (
    <Box
      sx={{
        width,
        height,
        flexShrink: 0,
        borderRadius: 1.5,
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <LoadingPlaceholderContainer isLoading={isLoading}>
        <ApexChart
          type={type}
          series={series}
          options={options}
          width="100%"
          height="100%"
        />
      </LoadingPlaceholderContainer>
    </Box>
  );
}
