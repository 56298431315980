import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import type { Editor } from '@tiptap/react';
import { useTranslation } from '@tyro/i18n';
import { AddIcon, TrashIcon } from '@tyro/icons';
import React from 'react';
import type { Dispatch, SetStateAction } from 'react';

export type TableMenuPosition = {
  mouseX: number;
  mouseY: number;
  pos: number; // This is the ProseMirror position of the clicked cell
} | null;

type TableCommand =
  | 'addColumnAfter'
  | 'addColumnBefore'
  | 'addRowAfter'
  | 'addRowBefore'
  | 'deleteRow'
  | 'deleteColumn'
  | 'deleteTable';

type TableMenuProps = {
  editor: Editor | null;
  tableMenu: TableMenuPosition;
  setTableMenu: Dispatch<SetStateAction<TableMenuPosition>>;
};

export function TableMenu({ editor, tableMenu, setTableMenu }: TableMenuProps) {
  const { t } = useTranslation(['common']);
  const executeTableCommand = (command: TableCommand) => {
    if (!editor) return;

    editor.chain().focus()[command]().run();
    handleTableMenuClose();
  };

  const handleTableMenuClose = () => {
    setTableMenu(null);
  };

  return (
    <Menu
      keepMounted
      open={tableMenu !== null}
      onClose={handleTableMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={
        tableMenu
          ? { top: tableMenu.mouseY, left: tableMenu.mouseX }
          : undefined
      }
    >
      <MenuList sx={{ width: 220, maxWidth: '100%' }}>
        {[
          {
            icon: <AddIcon />,
            action: () => executeTableCommand('addRowBefore'),
            text: t('common:tooltipTitles.insertRowBefore'),
          },
          {
            icon: <AddIcon />,
            action: () => executeTableCommand('addRowAfter'),
            text: t('common:tooltipTitles.insertRowAfter'),
          },
          {
            icon: <TrashIcon />,
            action: () => executeTableCommand('deleteRow'),
            text: t('common:tooltipTitles.deleteRow'),
          },
          {
            icon: <AddIcon />,
            action: () => executeTableCommand('addColumnBefore'),
            text: t('common:tooltipTitles.insertColumnBefore'),
          },
          {
            icon: <AddIcon />,
            action: () => executeTableCommand('addColumnAfter'),
            text: t('common:tooltipTitles.insertColumnAfter'),
          },
          {
            icon: <TrashIcon />,
            action: () => executeTableCommand('deleteColumn'),
            text: t('common:tooltipTitles.deleteColumn'),
          },
          {
            icon: <TrashIcon />,
            action: () => executeTableCommand('deleteTable'),
            text: t('common:tooltipTitles.deleteTable'),
          },
        ].map((menuItem) => (
          <MenuItem key={menuItem.text} onClick={menuItem.action}>
            <ListItemIcon>
              {menuItem.icon}
              <ListItemText sx={{ marginLeft: 1 }}>
                {menuItem.text}
              </ListItemText>
            </ListItemIcon>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
