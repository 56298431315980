import { useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import { useCallback } from 'react';

export const useRelativeDateFormat = () => {
  const { t } = useTranslation(['common']);

  const getRelativeDateFormat = useCallback(
    (dateAndTime: string) => {
      const time = dayjs(dateAndTime);

      const isSameDay = time.isSame(dayjs(), 'day');
      const isSameWeek = time.isSame(dayjs(), 'week');

      if (isSameDay) {
        const minutes = dayjs().diff(time, 'minutes');

        if (minutes <= 1) {
          return t('common:TimeAgo.now');
        }

        if (minutes < 60) {
          return t('common:TimeAgo.minutes', {
            minutes,
          });
        }

        return t('common:TimeAgo.hours', {
          hours: dayjs().diff(time, 'hour'),
        });
      }

      if (isSameWeek) {
        return t('common:TimeAgo.sameWeek', {
          dayName: time.format('ddd'),
          time: time.format('LT'),
        });
      }

      return time.format('lll');
    },
    [t],
  );

  return { getRelativeDateFormat };
};
