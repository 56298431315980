import { Chip, type ChipProps, Tooltip } from '@mui/material';
import { AddIcon } from '@tyro/icons';

export interface TableCellPlusButtonProps extends ChipProps {
  onClick: () => void;
  label: string;
  IconProps?: React.ComponentProps<typeof AddIcon>;
}

export function TableCellPlusButton({
  label,
  sx,
  IconProps,
  ...props
}: TableCellPlusButtonProps) {
  return (
    <Tooltip title={label}>
      <Chip
        aria-label={label}
        variant="soft"
        icon={<AddIcon {...IconProps} />}
        {...props}
        sx={{
          width: 32,
          height: 32,
          justifyContent: 'center',
          borderRadius: '50%',
          '& .MuiChip-label': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            margin: '0 !important',
          },
          ...sx,
        }}
      />
    </Tooltip>
  );
}
