import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { Box } from '@mui/material';
import type { Colour } from '@tyro/api';
import { useEffect, useRef, useState } from 'react';
import { ColorPicker } from '../../color-picker';

export function TableColorPicker(
  props: CustomCellEditorProps<unknown, Colour | undefined | null>,
) {
  const hasPressedArrowKey = useRef(false);
  const [editing, setEditing] = useState(true);
  const refContainer = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!editing) {
      props.stopEditing(false);
    }
  }, [editing]);

  useEffect(() => {
    window.setTimeout(() => {
      if (refContainer.current) {
        refContainer.current.focus();
      }
    });
  }, []);

  return (
    <Box p={1} bgcolor="white">
      <ColorPicker
        ref={refContainer}
        onKeyDown={(event) => {
          if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            hasPressedArrowKey.current = true;
          }
          if (event.key === 'Escape' || event.key === 'Enter') {
            setEditing(false);
          }
        }}
        value={props.value}
        onChange={(event) => {
          props.onValueChange(event.target.value as Colour);
        }}
        onClick={() => {
          if (!hasPressedArrowKey.current) {
            setEditing(false);
          }
          hasPressedArrowKey.current = false;
        }}
      />
    </Box>
  );
}
